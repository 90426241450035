import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";



function ContactPage() {
  return (
    <div className="contact-page">
      <NavBar />
      <Contact />
      <Footer />
    </div>
  );
}

export default ContactPage;
