import './NavBar.scss';
import Logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom';



export default function NavBar(){
    return(
        
            <div className='nav'>

                <div className='nav-menu'>
                    <Link to="/" className="nav-home-first">Home</Link>
                    <img className="nav-logo" 
                    src={Logo} 
                    alt="Bilbik Logo" />
                    <div className='nav-container'>
                    <Link to="/" className="nav-home-second">Home</Link>
                    <Link to="/contact-us" className="nav-us">Contact Us</Link>

                    </div>
                    

                </div>
                    


            </div>
    
    )
}