import './About.scss';
import { useSpring, animated } from 'react-spring';

export default function About(){

    const props = useSpring({
        from: { opacity: 0 }, // Start with an opacity of 0
        to: { opacity: 1 }, // Fade in to an opacity of 1
        config: { duration: 1500 }, // Animation duration in milliseconds
      });

    return(
        <div className='about'>
            <animated.h1 style={props} className='intro-text'>
                Welcome To Our Community <br></br> Where Every Fruit Tells A Story
            </animated.h1>            
            <div className='about-container-first'>
                <h2 className='about-title'>WHO WE ARE</h2>
                
                <div className='about-container-second'>
                    <p className='about-first'>
                    At "Bilbik Group," the essence of our journey is deeply personal. Founded by an individual with roots in the quaint orchards of Denizli, Turkey, and branches that have stretched to the expansive landscapes of Canada, our company is a celebration of heritage and health.
                    Two years ago, I made the leap across the world to embrace the Canadian dream, carrying with me the rich, earthy scents of my family’s small farm and the invaluable lessons of living in sync with nature. The contrast of life here, with the robust seasons and vibrant culture, only deepened my appreciation for the natural gifts of my homeland. 
                    </p>
                    <p className='about-second'>
                    We are more than just a brand; we are a testament to the bond between the soil of two distinct worlds. Our mission is to bring the delectable figs, the hearty persimmons, and the naturally sweet raisins from the sun-bathed fields of Denizli to your homes. Each piece of dried fruit is packed with the love and care that I’ve carried over thousands of miles—from my hands to yours.
                    Through "Bilbik Group," I invite you to experience not just the unique flavors of our meticulously sourced and prepared fruits but to become a part of a story that spans continents—a story where every product is a piece of my heart and a celebration of our beautiful planet.
                    
                    </p>
                </div>
                <div className='about-second-paragraph-div'>
                    <p className='about-second-paragraph'>
                    Join us in savoring the rich tapestry of nature, and let’s continue to grow together in this new land, always cherishing the bonds that connect us to our origins.
                    </p>
                </div>
                
            </div>
            
            

        </div>
    )
}

