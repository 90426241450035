import './Contact.scss';
import Email from '../../assets/images/mail.png';
import Address from '../../assets/images/address.png';
import Phone from '../../assets/images/phone.png'


export default function Contact(){
    return(
        <div className='contact'>

                <a className="contact-link" href={`mailto:Info@bilbikgroup.com`} target="_blank" rel="noopener noreferrer">
                    <img className="contact-image" src={Email} alt="Email Icon" /> 
                    Info@bilbikgroup.com
                </a>
                <p>
                    <img className="contact-image" src={Address} alt="Address Icon" />
                    717 Shaw St, Toronto ON, Canada
                </p>
                <p>
                    <img className="contact-image" src={Phone} alt="Phone Icon" />
                    +1 647 674 4036
                </p>
        </div>
    )
}