import NavBar from '../../components/NavBar/NavBar';
import About from '../../components/About/About';
import Section from '../../components/Section/Section';
import Footer from '../../components/Footer/Footer';
import './HomePage.scss';

function HomePage() {
  return (
    <div className="home-page"> 
      <NavBar />
      <div style={{ flex: 1 }}> 
        <Section />
        <About />
      </div>
      <Footer />
    </div>
  );
}


export default HomePage;
