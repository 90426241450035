import './App.scss';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import React from 'react';

import HomePage from './pages/HomePage/HomePage';
import ContactPage from './pages/ContactPage/ContactPage';



function App() {
  return (
    <div className="App">
      <BrowserRouter>

        <Routes>
           
            <Route path="/" element={<HomePage />} />
            <Route path="/contact-us" element={<ContactPage />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
