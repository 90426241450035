import React from 'react';
import Slider from 'react-slick';
import image1 from '../../assets/images/3.jpg';
import image2 from '../../assets/images/4.jpg';
import image3 from '../../assets/images/5.jpg';
import image4 from '../../assets/images/8.jpg';
import './Section.scss';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default function Section() {
    const settings = {
        dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    rtl: true
    };

    return (
        <div className="section">
            <div className='section-slider'>
            <Slider {...settings}>
                <div>
                    <img className='section-image' src={image1} alt="Dry Persimmon 1" />
                </div>
                <div>
                    <img className='section-image' src={image2} alt="Dry Persimmon 2" />
                </div>
                <div>
                    <img className='section-image' src={image3} alt="Dry Persimmon 3" />
                </div>
                <div>
                    <img className='section-image' src={image4} alt="Dry Persimmon 4" />
                </div>
            </Slider>
            </div>
            
        </div>
    );
}
